import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

@Component({
	selector: '[bas-anchor-status]',
	templateUrl: './anchor-status.component.html',
	styleUrls: ['./anchor-status.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'[class.nurFuerBasUndAdminSichtbar]': 'nurFuerBasUndAdminSichtbar()',
		'[class.nurFuerBasSichtbar]': 'nurFuerBasSichtbar()',
		'[class.abgelaufen]': 'abgelaufen()',
		'[class.fuerShowHiddenSichtbar]': 'fuerShowHiddenSichtbar()',
	},
})
export class AnchorStatusComponent {

	parameters = input.required<Record<string, boolean>>();
	nurFuerBasUndAdminSichtbar = computed(() => this.parameters()['nurFuerBasUndAdminSichtbar'] || false);
	nurFuerBasSichtbar = computed(() => this.parameters()['nurFuerBasSichtbar'] || false);
	abgelaufen = computed(() => this.parameters()['abgelaufen'] || false);
	fuerShowHiddenSichtbar = computed(() => this.parameters()['fuerShowHiddenSichtbar'] || false);

}

import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

const animationtime = 0.4;

/**
 * @group Generic Compounds
 * @component Panel
 */
@Component({
	selector: 'bas-panel',
	templateUrl: './panel.component.html',
	styleUrls: ['./panel.component.less'],
	animations: [
		trigger('toggle', [
			state('closed', style({
				'display': 'none',
				'overflow': 'hidden'
			})),
			state('open', style({
				'overflow': 'visible'
			})),
			transition('open => closed', [
				style({
					'overflow': 'hidden'
				}),
				animate('.4s cubic-bezier(.25,.8,.25,1)', style({
					'height': '0',
					'padding-top': '0',
					'padding-bottom': '0',
				}))
			]),
			transition('closed => open', [
				style({
					'display': 'none',
					'height': '0',
					'padding-top': '0',
					'padding-bottom': '0',
					'overflow': 'hidden'
				}),
				animate(animationtime + 's cubic-bezier(.25,.8,.25,1)', style({
					'height': '*',
					'padding-top': '*',
					'padding-bottom': '*',
				}))
			])
		])
	],
	host: {
		'[class.hasError]': '_hasError',
	},
})
export class PanelComponent implements OnInit, OnDestroy {

	@Input()
	name: string;

	_hasError = false;

	set hasError(hasError: boolean) {
		const hasChanged = hasError !== this._hasError;
		if (hasChanged) {
			this._hasError = hasError;
			if (hasError) {
				this.actualState$.next(true);
			}
			this.cdr.markForCheck();
		}

	}

	@Input()
	inputState$: Observable<boolean>;

	@Input()
	centerOnClick = false;

	@Input()
	inputState: boolean;

	@Output()
	actualState$: BehaviorSubject<boolean> = new BehaviorSubject(null);

	animationState$;

	subscriptions = new Subscriber();

	constructor(public cdr: ChangeDetectorRef, private elementRef: ViewContainerRef) {

	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngOnInit(): void {
		if (this.inputState !== undefined) {
			this.actualState$.next(this.inputState);
		}
		if (this.inputState$) {
			this.subscriptions.add(this.inputState$
				.subscribe(newState => {
					if (this.actualState$.value !== newState) {
						this.actualState$.next(newState);
						this.cdr.detectChanges();
					}
				}));
		}
		this.animationState$ = this.actualState$.pipe(
			distinctUntilChanged(),
			map(newState => newState ? 'open' : 'closed')
		);
	}

	next(): void {
		this.actualState$.next(!this.actualState$.value);
		this.cdr.detectChanges();
	}
}

import {
	ChangeDetectionStrategy, Component, computed, DestroyRef, EventEmitter, input, OnInit, Output, ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

type ButtonColor = 'accent' | 'primary';

/**
 * @group Elements
 * @component Buttons
 */
@Component({
	selector: '[bas-button]',
	template: '<ng-content></ng-content>',
	styleUrls: ['./button.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'[class.disabled]': 'disabled()',
		'[attr.type]': '"button"',
		'[attr.tabindex]': 'tabindex()',
		'[class]': 'color()',
	},
})
export class ButtonComponent implements OnInit {

	disabled = input(false);
	specificTabindex = input<number>(null);
	color = input('primary', {
		transform: (value: ButtonColor): ButtonColor => value.trim() as ButtonColor,
	});

	/** Binding auf [tabindex] => -1 falls disabled */
	tabindex = computed(() => {
		const specificTabindex = this.specificTabindex();
		const disabled = this.disabled();
		return specificTabindex || (disabled ? -1 : null);
	});

	@Output('throttledClick')
	throttledClick = new EventEmitter<boolean>();

	constructor(
		private elementRef: ViewContainerRef,
		private destroyRef: DestroyRef,
	) {
	}

	ngOnInit(): void {
		const clickObservable = fromEvent(this.elementRef.element.nativeElement, 'click');
		clickObservable.pipe(
			throttleTime(300),
			takeUntilDestroyed(this.destroyRef),
		)
			.subscribe(() => this.throttledClick.emit(true));
	}

	public blur(): void {
		this.elementRef.element.nativeElement.blur();
	}
}
